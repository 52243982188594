<!-- eslint-disable vue/no-v-model-argument -->
<template>
  <h3 class="card-page-title mt-4 mb-3">
    <img
      src="@/assets/img/property-feat/feat-form-title.png"
      alt="title"
      class="img-fluid" />
  </h3>
  <div v-if="isLoad" class="wrap-ct border-0">
    <div class="text-blue3 my-4 text-center font-weight-bold fs-20">
      {{ infoPage.title }} 来場予約
    </div>
    <form>
      <div class="custom-callout">希望見学日</div>
      <div class="cus-filter custom-form border-0 pl-3 pl-sm-5 mb-5">
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2"
            >第1希望日 <span class="badge badge-red">必 須</span></label
          >
          <div class="col-sm-6">
            <InputDatePicker
              v-model:modelvalue="dataParams.hopeDay1"
              :class="{
                'field-error':
                  dataParams.hopeDay1 == '' && startCheck && !formConfirm(),
              }"
              :placeholder="'入力してください'"
              :typeformat="'be'"
              :mindate="new Date()"
              :maxdate="new Date(Date.now() + 6.048e8 * 2)"
              :disabled="formConfirm()" />
            <div
              v-if="dataParams.hopeDay1 == '' && startCheck && !formConfirm()"
              class="form-error text-red2 fs-12 mb-2">
              入力項目に不足、または不備があります。
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2">第2希望日</label>
          <div class="col-sm-6">
            <InputDatePicker
              v-model:modelvalue="dataParams.hopeDay2"
              :placeholder="'入力してください'"
              :typeformat="'be'"
              :mindate="new Date()"
              :maxdate="new Date(Date.now() + 6.048e8 * 2)"
              :disabled="formConfirm()" />
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2">第3希望日</label>
          <div class="col-sm-6">
            <InputDatePicker
              v-model:modelvalue="dataParams.hopeDay3"
              :placeholder="'入力してください'"
              :typeformat="'be'"
              :mindate="new Date()"
              :maxdate="new Date(Date.now() + 6.048e8 * 2)"
              :disabled="formConfirm()" />
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2"
            >来場予定人数</label
          >
          <div class="col-sm-4">
            <div class="custom-select2 w-100 form-group">
              <FieldSelect2
                v-model="dataParams.selectAttendees"
                :options="dataAttendees"
                :keyid="'id'"
                :keytext="'text'"
                :placeholder="'振分先頭'"
                :disabled="formConfirm()" />
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2">ご要望</label>
          <div class="col-sm-8">
            <textarea
              v-model="dataParams.request"
              rows="4"
              class="form-control"
              placeholder="入力してください"
              maxlength="9999"
              :disabled="formConfirm()"></textarea>
          </div>
        </div>
      </div>
      <div class="custom-callout">ご連絡先</div>
      <div class="cus-filter custom-form border-0 pl-3 pl-sm-5">
        <p class="color_a0a0a0">
          ※ご予約日時確定につきましては、お電話またはメールにて担当者よりご連絡させていただきます。<br />定休日の場合、ご連絡までに3日程度時間を要する場合がございます。予めご了承ください。
        </p>
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2"
            >電話番号 <span class="badge badge-red">必 須</span></label
          >
          <div class="col-sm-6">
            <div class="form-group">
              <input
                v-model="dataParams.phone"
                type="tel"
                class="form-control"
                :class="{
                  'field-error':
                    dataParams.phone == '' && startCheck && !formConfirm(),
                }"
                placeholder="入力してください"
                data-toggle="datetimepicker"
                maxlength="15"
                :disabled="formConfirm()"
                @keypress="checkPhone"
                @paste="checkPhone" />
            </div>
            <div
              v-if="dataParams.phone == '' && startCheck && !formConfirm()"
              class="form-error text-red2 fs-12 mb-2">
              入力項目に不足、または不備があります。
            </div>
            <div
              v-if="checkIsPhone() && dataParams.phone != ''"
              class="form-error text-red2 fs-12 mb-2">
              電話番号は半角数字、および半角ハイフン(-)のみ使用できます。
            </div>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 font-weight-normal mt-sm-2"
            >ご希望連絡時間帯</label
          >
          <div class="col-sm-8">
            <div class="custom-select2 w-100 form-group">
              <FieldSelect2
                v-model="dataParams.selectContactTime"
                :options="dataContactTime"
                :keyid="'text'"
                :keytext="'text'"
                :placeholder="'ご都合のよろしい時間帯をお選びください'"
                :disabled="formConfirm()" />
            </div>
          </div>
        </div>
        <p class="mt-5">個人情報の取り扱いについて（必ずお読みください）</p>
        <p>
          <a
            href="https://www.livable.co.jp/Privacy_policy.html"
            class="bg-arrow-right txt-underline"
            target="_blank"
            >東急リバブル プライバシーポリシー</a
          >
        </p>
        <div class="form-group">
          <div class="form-check custom-checkbox mr-3">
            <input
              id="visit-confirm"
              v-model="isConfirm"
              class="form-check-input"
              :class="{
                'field-error': !isConfirm && startCheck && !formConfirm(),
              }"
              type="checkbox"
              :disabled="formConfirm()" />
            <label for="visit-confirm">上記に同意する</label>
          </div>
          <div
            v-if="!isConfirm && startCheck && !formConfirm()"
            class="form-error text-red2 fs-12 mt-2">
            入力項目に不足、または不備があります。
          </div>
        </div>
        <div v-if="formConfirm()" class="my-3">
          上記入力内容に間違いがなければ【送信】ボタンをクリックしてください。
        </div>
      </div>
      <div v-if="!formConfirm()" class="text-center mb-5 font-weight-bold">
        <button
          type="button"
          class="btn submit-pink m-3"
          :disabled="!isEnterFeild()"
          @click="checkSubmit">
          確認画面
        </button>
        <button
          type="button"
          class="btn submit-pink submit-border-pink m-3"
          @click="backRecomend">
          物件紹介ページに戻る
        </button>
      </div>
      <div v-if="formConfirm()" class="text-center mb-5">
        <button
          type="button"
          class="btn submit-pink m-3"
          @click="showModal('modalConfirm')">
          送信
        </button>
        <button
          type="button"
          class="btn submit-pink submit-border-pink m-3"
          @click="backForm">
          戻る
        </button>
      </div>
    </form>
  </div>
  <ModalConfirmSubmit
    :id="'modalConfirm'"
    :message="'来場予約フォームを送信します。'"
    @yes="submitForm" />
</template>

<script>
import InputDatePicker from "/src/components/utilitys/inputs/InputDatePicker.vue";
import FieldSelect2 from "/src/components/utilitys/select/FieldSelect2.vue";
import { dataContactTime, dataAttendees } from "./data.js";
import {
  addInquiryHistories,
  // checkInquiryHistories,
} from "/src/service/inquiry.service.js";
import {
  getRecommendProperties,
  getNewMailPropertyDetail,
} from "/src/service/recommend.service.js";
import ModalConfirmSubmit from "/src/components/modals/ModalConfirmSubmit.vue";
import { mixinModal } from "/src/mixins";

export default {
  name: "VisitingReservationForm",
  components: {
    InputDatePicker,
    FieldSelect2,
    ModalConfirmSubmit,
  },
  mixins: [mixinModal],
  data() {
    return {
      dataParams: {
        hopeDay1: "",
        hopeDay2: null,
        hopeDay3: null,
        selectAttendees: 1,
        request: "",
        phone: "",
        selectContactTime: null,
      },
      recommendParams: {
        history_id: null,
        customer_id: null,
        reference_page_url: null,
        pre_submit: true,
      },
      isConfirm: false,
      startCheck: false,
      isSubmit: false,
      dataContactTime,
      dataAttendees,
      recommendProperties: null,
      infoPage: {
        title: "",
      },
      typeSubmit: null,
      isLoad: false,
    };
  },
  watch: {
    followDate() {
      this.changeFollowDate();
    },
  },
  created() {
    this.checkReservationData();
    localStorage.setItem("postvisit", false);
    localStorage.setItem("visitresersubmitstatus", false);
    if (this.$route.params.uuid)
      localStorage.setItem("visitreserbackurl", this.$route.fullPath);
  },
  methods: {
    async fetchRecommend() {
      const response = await getRecommendProperties(this.$route.params.uuid);
      if (response.data) {
        this.isLoad = true;
        this.typeSubmit = "url";
        this.recommendProperties = response.data.recommend_page_url;
        this.infoPage.title = response.data.property.name;
        localStorage.setItem("visitresertitle", response.data.property.name);
      } else {
        this.$router.push({ name: "error404" });
      }
    },
    async fetchNewMail() {
      const response = await getNewMailPropertyDetail(
        this.$route.params.newuuid
      );
      if (response.data) {
        this.isLoad = true;
        this.typeSubmit = "urlmail";
        this.recommendProperties = response.data.new_mail_property_page_url;
        this.infoPage.title = response.data.property.name;
        localStorage.setItem("visitresertitle", response.data.property.name);
      } else {
        this.$router.push({ name: "error404" });
      }
    },
    // async fetchInquiry(param, type) {
    //   const response = await checkInquiryHistories(param);
    //   if (response) {
    //     this.typeSubmit = type;
    //     if (response.data.property_name) {
    //       this.infoPage.title = response.data.property_name;
    //       localStorage.setItem("visitresertitle", response.data.property_name);
    //     }
    //   } else {
    //     this.$router.push({ name: "error404" });
    //   }
    // },
    checkReservationData() {
      // let myquery = this.$route.query;
      // let countKey = Object.keys(myquery).length;
      if (this.$route.params.uuid) {
        this.fetchRecommend();
      } else if (this.$route.params.newuuid) {
        this.fetchNewMail();
      }
      // if (
      //   myquery.history_id &&
      //   myquery.customer_id > 0 &&
      //   myquery.reference_page_url != "" &&
      //   countKey == 3
      // ) {
      //   this.recommendParams.history_id = parseInt(myquery.history_id);
      //   this.recommendParams.customer_id = parseInt(myquery.customer_id);
      //   this.recommendParams.reference_page_url = myquery.reference_page_url;
      //   this.fetchInquiry(this.recommendParams, "follow");
      // } else
      else {
        this.$router.push({ name: "error404" });
      }
    },
    checkSubmit(ev) {
      ev.preventDefault();
      this.checkField();
      if (this.isSubmit && !this.formConfirm()) {
        this.$router.push({ name: "visiting-confirm" });
        return false;
      }
      if (this.formConfirm()) {
        this.submitForm();
      }
    },
    submitForm() {
      let body = {
        telephone_number: this.dataParams.phone,
        inquiry_note: this.dataParams.request,
        preferred_contact_time: this.dataParams.selectContactTime,
        number_of_visitors: this.dataParams.selectAttendees,
        preferred_date_1: this.dataParams.hopeDay1,
        preferred_date_2: this.dataParams.hopeDay2,
        preferred_date_3: this.dataParams.hopeDay3,
      };
      if (this.typeSubmit == "url") {
        body.recommend_page_url = this.recommendProperties;
      }
      if (this.typeSubmit == "urlmail") {
        body.new_mail_property_page_url = this.recommendProperties;
      }
      // if (this.typeSubmit == "follow") {
      //   body.history_id = this.recommendParams.history_id;
      //   body.customer_id = this.recommendParams.customer_id;
      //   body.reference_page_url = this.recommendParams.reference_page_url;
      // }
      if (this.formConfirm())
        addInquiryHistories(body)
          .then((_) => {
            localStorage.setItem("postvisit", true);
            localStorage.setItem("visitresersubmitstatus", true);
            this.$router.push({ name: "visiting-finish" });
          })
          .catch((_) => {
            localStorage.setItem("postvisit", true);
            localStorage.setItem("visitresersubmitstatus", false);
            this.$router.push({ name: "visiting-finish" });
          });
    },
    backForm(ev) {
      ev.preventDefault();
      this.$router.go(-1);
    },
    backRecomend(ev) {
      ev.preventDefault();
      let urlRecomend = this.$route.path.split("/");
      let nameRoute = urlRecomend.includes("new")
        ? "new_mail_recommend"
        : "recommend";
      this.$router.push({
        name: nameRoute,
        params: { uuid: this.recommendProperties },
      });
    },
    checkField() {
      this.startCheck = true;
      if (
        this.isConfirm == false ||
        this.dataParams.hopeDay1 == "" ||
        this.dataParams.phone == ""
      ) {
        this.isSubmit = false;
      } else {
        this.isSubmit = true;
      }
    },
    checkPhone(evt) {
      var key;
      if (evt.type === "paste") {
        key = evt.clipboardData.getData("text/plain");
      } else {
        key = evt.keyCode || evt.which;
        key = String.fromCharCode(key);
      }
      var regex = /[0-9]|-/;
      if (!regex.test(key) || evt.target.value.length > 15) {
        evt.returnValue = false;
        if (evt.preventDefault) evt.preventDefault();
      }
    },
    checkIsPhone() {
      var regex = /^[0-9]|[0-9]-[0-9]$/;
      if (!regex.test(this.dataParams.phone)) {
        return true;
      }
      return false;
    },
    formConfirm() {
      return this.$route.name == "visiting-confirm";
    },
    isEnterFeild() {
      if (
        this.dataParams.hopeDay1 == "" &&
        this.dataParams.hopeDay2 == null &&
        this.dataParams.hopeDay3 == null &&
        this.dataParams.request == "" &&
        this.dataParams.phone == "" &&
        this.dataParams.selectContactTime == null &&
        this.isConfirm == false
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>
