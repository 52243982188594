const DEBUG = true;

// Ending Environment Variables Handling
const USER_ROLE = {
  VIEWER: 1,
  EDITOR: 3,
  ADMIN: 7,
};
const API_DELAY = 100; // Delay between call 2 APIs in milliseconds

const PROPERTY_TYPE = {
  PROPERTY: 1,
  SALESFORCE_PROPERTY: 2,
};

const ORIGINAL_LOCATION = {
  DYNAMIC: 1,
  SALESFORCE: 2,
  OTHER: 3,
};

const ENV_NAME =
  {
    "intra-stg.livable-ai-recommend.jp": "stg",
    "www-stg.livable-ai-recommend.jp": "stg",
    "intra.livable-ai-recommend.jp": "prod",
    "www.livable-ai-recommend.jp": "prod",
  }[location.hostname] || "dev";

const CUSTOMER_DOMAIN =
  {
    dev: "https://www-dev.livable-ai-recommend.jp",
    stg: "https://www-stg.livable-ai-recommend.jp",
    prod: "https://www.livable-ai-recommend.jp",
  }[ENV_NAME] || "https://www-dev.livable-ai-recommend.jp";

const IMAGE_PROPERTIES = CUSTOMER_DOMAIN + "/static/properties/";
const MANAGER_WORKING_STYLE = [
  { label: "", value: null },
  { label: "常勤", value: 1 },
  { label: "日勤", value: 2 },
  { label: "巡回", value: 3 },
  { label: "管理員なし", value: 4 },
];
const MANAGEMENT_TYPE = [
  { label: "", value: null },
  { label: "全部委託", value: 1 },
  { label: "一部委託", value: 2 },
  { label: "自主管理", value: 3 },
];
const PARKING_LOT_STATUS = [
  { label: "", value: null },
  { label: "不明", value: 0 },
  { label: "有り", value: 1 },
  { label: "無し", value: 2 },
];
const BLOCK_LAYOUT_TYPE = [
  { label: "", value: null },
  { label: "ワンルーム", value: 1 },
  { label: "1K", value: 2 },
  { label: "1DK", value: 3 },
  { label: "1LDK", value: 4 },
  { label: "1LDK+S", value: 5 },
  { label: "1LDK+N", value: 6 },
  { label: "2LDK", value: 7 },
  { label: "2LDK+S", value: 8 },
  { label: "2LDK+N", value: 9 },
  { label: "3LDK", value: 10 },
  { label: "3LDK+S", value: 11 },
  { label: "3LDK+N", value: 12 },
  { label: "4LDK", value: 13 },
  { label: "4LDK+S", value: 14 },
  { label: "4LDK+N", value: 15 },
  { label: "5LDK以上", value: 16 },
];
const BLOCK_SELLING_STATUS = [
  { label: "", value: null },
  { label: "販売準備中", value: "販売準備中" },
  { label: "販売中", value: "販売中" },
  { label: "中止", value: "中止" },
  { label: "成約", value: "成約" },
];
const BLOCK_CLASSIFICATION = [
  { label: "", value: null },
  { label: "新築", value: "新築" },
  { label: "完成後未入居", value: "完成後未入居" },
  { label: "中古", value: "中古" },
  { label: "リノベーション", value: "リノベーション" },
];
export {
  DEBUG,
  USER_ROLE,
  API_DELAY,
  ENV_NAME,
  PROPERTY_TYPE,
  ORIGINAL_LOCATION,
  IMAGE_PROPERTIES,
  MANAGER_WORKING_STYLE,
  MANAGEMENT_TYPE,
  PARKING_LOT_STATUS,
  BLOCK_LAYOUT_TYPE,
  BLOCK_SELLING_STATUS,
  BLOCK_CLASSIFICATION,
};
