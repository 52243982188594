// const HOST = "http://localhost:8000/"; // Change to this when running separately

const HOST = `//${location.host}/`; // Change to this when serving from backend
const API_HOST = HOST + "api/v1/";
// USER API
const USER_TOKEN = API_HOST + "user_info";
const USER_API = API_HOST + "users";
const CSRF_API = API_HOST + "csrf_token";
// PROPERTY API
const PROPERTIES_API = API_HOST + "properties";
const PROPERTY_BLOCKS_API = API_HOST + "property_blocks";
const PRIORITIES_CUSTOMERS_API = API_HOST + "follow_priority_customers";
// CUSTOMER API
const RECOMMEND_CUSTOMER_API = API_HOST + "recommended_customers";
const RECOMMEND_PROPERTIES_API = API_HOST + "recommend_properties";
const CUSTOMERS_API = API_HOST + "customers";
const CUSTOMER_ACTION_HISTORIES_API = API_HOST + "customer_action_histories";
const CLOSED_PROPERTIES_API = API_HOST + "closed_properties";
const SALES_FOLLOW_HISTORIES_API = API_HOST + "sales_follow_histories";
// EMAILS
const RECOMMEND_EMAILS = API_HOST + "recommend_mails";
// LOGOUT
const LOGOUT_API = API_HOST + "logout";
const SALESFORCE_PROPERTIES_API = API_HOST + "salesforce_properties";
// INQUIRY
const INQUIRY_HISTORIES_API = API_HOST + "inquiry_histories";
const SALES_COMPANIES_API = API_HOST + "sales_companies";
//histories
const TOP_FOLLOW_HISTORIES = API_HOST + "top_follow_histories";
//aggregate
const AGGREGATE_API = API_HOST + "tasks/aggregate";
const RECOMMEND_SCHELDULE_CALENDARS =
  API_HOST + "recommend_schedule_calendars/valid_trading_date_range";
//newmail
const NEW_MAILS = API_HOST + "new_mails";
const NEW_MAIL_PROPERTIES = API_HOST + "news_mail_properties";
const NEW_MAIL_FOLLOW_PRIORITY_CUSTOMER =
  API_HOST + "new_mail_follow_priority_customers";
const NEW_MAIL_CONTENT = API_HOST + "new_mail_contents";
const NEW_MAIL_CONTENT_REPORT = NEW_MAIL_CONTENT + "/report";
const NEW_MAILS_DELETE = NEW_MAILS + "/delete";

export {
  CSRF_API,
  CLOSED_PROPERTIES_API,
  CUSTOMER_ACTION_HISTORIES_API,
  CUSTOMERS_API,
  INQUIRY_HISTORIES_API,
  LOGOUT_API,
  PRIORITIES_CUSTOMERS_API,
  PROPERTIES_API,
  PROPERTY_BLOCKS_API,
  RECOMMEND_CUSTOMER_API,
  RECOMMEND_EMAILS,
  RECOMMEND_PROPERTIES_API,
  SALES_COMPANIES_API,
  SALES_FOLLOW_HISTORIES_API,
  SALESFORCE_PROPERTIES_API,
  USER_API,
  USER_TOKEN,
  TOP_FOLLOW_HISTORIES,
  AGGREGATE_API,
  RECOMMEND_SCHELDULE_CALENDARS,
  NEW_MAILS,
  NEW_MAIL_PROPERTIES,
  NEW_MAIL_FOLLOW_PRIORITY_CUSTOMER,
  NEW_MAIL_CONTENT_REPORT,
  NEW_MAIL_CONTENT,
  NEW_MAILS_DELETE,
};
